import { createRouter, createWebHashHistory } from 'vue-router'

import index from '../views/homePage.vue'
import detail from '../views/detailPage.vue'
import player from '@/views/playerPage.vue'
import tvhome from '@/views/tvHomePage.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/play',
    name: 'player',
    component: player
  },
  {
    path: '/dramas',
    name: 'dramas',
    component: tvhome
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 路由守卫，将无信息界面返回首页，重新请求信息
router.beforeEach((to, from, next) => {
  const isBack = sessionStorage.mlist
  if (to.path === '/') {
    next()
  } else {
    isBack ? next() : next('/')
  }
})

export default router
