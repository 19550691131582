<template>
  <div class="mcard">
    <div v-if="props.item.episode" class="episode">
      {{ props.item.episode }}
    </div>
    <div class="score">{{ props.item.score }}</div>
    <div class="img">
      <img :src="props.item.poster" @click="minfo" />
    </div>

    <div class="listTitle">
      <p>{{ props.item.name }}</p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  arr: Array,
  item: {}
})
sessionStorage.setItem('tve', props.item.episode)
const minfo = () => {
  router.push({
    name: 'detail',
    params: {
      id: props.item._id,
      poster: props.item.poster,
      name: props.item.name,
      episode: props.item.episode
    }
  })
}
</script>

<style lang="scss" scoped>
.mcard {
  text-align: center;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  .score {
    position: absolute;
    margin: 0.2%;
    background: #37a;
    font-size: 85%;
    line-height: 120%;
    z-index: 1;
    padding: 0.2%;
    border-radius: 30%;
    align-self: flex-end;
  }
  .episode {
    position: absolute;
    background: #37a;
    margin: 0.2% 0 0 0.2%;
    width: 50px;
    font-size: 80%;
    z-index: 1;
    align-self: flex-start;
    border: 0;
    @media (max-width: 600px) {
      margin: 0.2% 0 0 0;
      padding: 0.2% 0 0.2% 0;
      line-height: 8px;
      height: 8px;
      font-size: 100%;
      width: 8%;
    }
  }
  .img {
    border-radius: 5%;
    overflow: hidden;
    flex: 6;

    .wait {
      background-color: #c9ccd0;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 5%;
    }

    img:hover {
      transition: all 0.6s; /*图片放大过程的时间*/
      transform: scale(1.05); /*鼠标经过图片放大的倍数*/
      cursor: pointer;
    }
  }
  .listTitle {
    flex: 1;
    font-size: 18px;
    p {
      margin-top: 2%;

      @media (max-width: 600px) {
        height: 30px;
        flex: 1;
        margin: 0;
        // overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }
}
</style>
